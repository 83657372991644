import {
  installRegisteredApps,
  maybeInstallMembersArea,
} from '@wix/members-area-integration-kit';
import EditorWrapper from './EditorWrapper';
import {
  CHALLENGE_PAGE_ID,
  CHALLENGES_INSTALL_MIGRATION_TYPE,
  CHALLENGES_LIST_PAGE_ID,
  CHALLENGES_LIST_PAGE_TYPE,
  CHALLENGES_PAGES,
  GROUPS_APP_ID,
  PAID_PLANS_APP_ID,
} from './app-config';
import { getChallengesManifest } from './manifest';
import AppManifest, { AppManagerEvent } from './types/manifest';
import { ISetupPagesOptions, TPARef } from './types/common';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { Challenges } from './types/Experiments';
import { CHALLENGES_BM_ROUTES } from '../config/constants';
import { installParticipantPage } from './migrations/installParticipantPage';

export class ChallengesPlatform {
  private readonly editor: EditorWrapper;
  private readonly translations;
  private flowAPI: EditorScriptFlowAPI;

  constructor(
    editorSDK,
    public readonly appDefId: string,
    flowAPI: EditorScriptFlowAPI,
    public readonly isADI?: boolean,
    public readonly isEditorWizardCreationFlow?: boolean,
  ) {
    this.editor = new EditorWrapper(editorSDK, appDefId);
    this.translations = flowAPI.translations;
    this.flowAPI = flowAPI;
  }

  async isChallengesInstalled() {
    return this.editor.isAppInstalled(this.appDefId);
  }

  async installTPA(appDefId: string) {
    const isTPAInstalled = await this.editor.isAppInstalled(appDefId);

    if (!isTPAInstalled) {
      await this.editor.installTPA(appDefId);
    }
  }

  async handleMigration(payload: {
    migrationType: CHALLENGES_INSTALL_MIGRATION_TYPE;
  }) {
    if (payload?.migrationType === 'install_pricing_plans') {
      await this.installTPA(PAID_PLANS_APP_ID);
    }

    if (payload?.migrationType === 'install_groups') {
      await this.installTPA(GROUPS_APP_ID);
    }
  }

  async installDependencies() {
    // skipping all of the installations of TPA. In Classic Autopilot will install it.
    if (this.flowAPI.experiments.enabled(Challenges.skipMAGroupsInstall)) {
      return Promise.resolve();
    }

    const isClassicEnabled = this.flowAPI.experiments.enabled(
      Challenges.enableAutopilot,
    );
    const isEditorXEnabled = this.flowAPI.experiments.enabled(
      Challenges.enableAutopilotEditorX,
    );

    // todo: need to add meaningful comments here or rewrite the conditionals
    const { isClassicEditor, isEditorX } = this.flowAPI.environment;
    const isInstallApps =
      (isClassicEditor && !isClassicEnabled) ||
      (isEditorX && !isEditorXEnabled);

    if (isInstallApps) {
      await this.installTPA(PAID_PLANS_APP_ID);
      await this.installTPA(GROUPS_APP_ID);

      await this.editor.openAppPage(CHALLENGES_LIST_PAGE_ID, this.appDefId);
    }
  }

  async setupProgramsPages(options: ISetupPagesOptions) {
    const { isFirstInstall } = options;
    const challengesPages = await this.challengesPagesOnStage();

    // magic begins. We need to use new layout (Sidebar) for new users by default,
    //     this code setting value to public data that we can use later in SP/Controller/Widget as regular setting key.
    if (isFirstInstall && !this.isADI) {
      const challengePageTpaSection = await this.editor.getTpaSection(
        CHALLENGE_PAGE_ID,
        challengesPages,
      );

      if (!challengePageTpaSection) {
        return;
      }

      await this.editor.setPublicData(
        {
          id: challengePageTpaSection.id,
          type: 'DESKTOP',
        },
        {
          key: 'visitorPageBodyEnableSidebar',
          value: true,
        },
      );

      const challengeListPageTpaSection = await this.editor.getTpaSection(
        CHALLENGES_LIST_PAGE_ID,
        challengesPages,
      );
      if (!challengeListPageTpaSection) {
        return;
      }
      await this.editor.setPublicData(
        {
          id: challengeListPageTpaSection.id,
          type: 'DESKTOP',
        },
        {
          key: 'dpListTitle',
          value: true,
        },
      );
    }
    // end of maaaaagic

    await this.editor.setPageInitialTitles(challengesPages, this.translations);

    await this.editor.addPagesToEditorPageMenu(challengesPages);

    if (!isFirstInstall) {
      try {
        this.flowAPI.fedops.interactionStarted('call-install-page-editor');
        await installParticipantPage(
          this.flowAPI,
          this.editor,
          challengesPages,
        );
        this.flowAPI.fedops.interactionEnded('call-install-page-editor');
      } catch (error) {}
    }
  }

  async setupMA({ isFirstInstall = false }: { isFirstInstall: boolean }) {
    const isSkipMAInstallationEnabled = this.flowAPI.experiments.enabled(
      Challenges.skipMAInstallation,
    );
    const skipMAInstallation =
      this.isEditorWizardCreationFlow && isSkipMAInstallationEnabled;
    // https://wix.slack.com/archives/CM11JK8NA/p1655885110600659

    if (isFirstInstall && !skipMAInstallation) {
      await maybeInstallMembersArea();
      await installRegisteredApps();
    }
  }

  private getChallengesTPARefs(allSitePages, applicationId): TPARef[] {
    return allSitePages
      .filter(
        (page) =>
          page.tpaApplicationId === applicationId &&
          CHALLENGES_PAGES.includes(page.tpaPageId),
      )
      .map(({ id, tpaPageId, title, managingAppDefId }) => {
        return {
          title,
          tpaPageId,
          managingAppDefId,
          pageRef: { id },
        };
      });
  }

  async getManifest({ appManifestBuilder }): Promise<AppManifest> {
    return getChallengesManifest<typeof this.flowAPI.experiments>({
      appManifestBuilder,
      translations: this.translations,
      openDashboard: this.openDashboard.bind(this),
      experiments: this.flowAPI.experiments,
    });
  }

  async deleteApp(_eventPayload: {
    origin: string;
    publicUrl: string;
    pageRef: { id: string; type: string };
  }) {
    try {
      await this.editor.deleteApp();
    } catch (e) {
      console.warn('[challenges]', e);
    }
  }

  async challengesPagesOnStage() {
    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);

    const { applicationId } = tpaData;
    return this.getChallengesTPARefs(allSitePages, applicationId);
  }

  async getPageOnStage(pageId: string) {
    const challengesPages = await this.challengesPagesOnStage();

    return challengesPages.find((page) => page.tpaPageId === pageId);
  }

  async getTPASectionId(pageId: string): Promise<string> {
    const challengesPages = await this.challengesPagesOnStage();
    const challengePageTpaSection = await this.editor.getTpaSection(
      pageId,
      challengesPages,
    );

    return challengePageTpaSection?.id || '';
  }

  async openDashboard(actionId: AppManagerEvent) {
    if (actionId === AppManagerEvent.OPEN_DASHBOARD) {
      return this.editor.openDashboard(CHALLENGES_BM_ROUTES.Home);
    }
    if (actionId === AppManagerEvent.OPEN_CREATE_CHALLENGE) {
      return this.editor.openDashboard(CHALLENGES_BM_ROUTES.CreateProgram);
    }

    if (actionId === AppManagerEvent.OPEN_SP) {
      await this.editor.openAppPage(CHALLENGES_LIST_PAGE_ID, this.appDefId);

      const challengeListPageId = await this.getTPASectionId(
        CHALLENGES_LIST_PAGE_ID,
      );

      try {
        await this.editor.openSP(
          {
            id: challengeListPageId,
            type: 'DESKTOP',
          },
          this.appDefId,
          CHALLENGES_LIST_PAGE_TYPE,
        );
      } catch (e) {
        console.error('Error on open SP from App Manager:', e);
      }
    }

    return;
  }

  // todo: use this method for real errors
  handleInstallError() {}
}
